<template>
  <app-layout>
    <page-header> Adauga produs </page-header>

    <page-section>
      <product-form
        :service="productsApi"
        method="store"
        @created:product="redirect()"
      />
    </page-section>
  </app-layout>
</template>

<script>
import productsApi from "../api/resources/productsApi";
import AppLayout from "../layout/AppLayout.vue";
import ProductForm from "../components/shared/ProductForm.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";

export default {
  components: {
    AppLayout,
    ProductForm,
    PageHeader,
    PageSection,
  },

  data() {
    return {
      productsApi: productsApi,
    };
  },

  methods: {
    redirect() {
      this.$router.push({ name: "products.index" });
    },
  },
};
</script>

<style></style>
